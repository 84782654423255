//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  name: "about",

  data() {
    return {
      apiTitle: this.$api.api_title + 'images/',
      img_list: ['https://www.newhorizonbio.com//uploadImage/20220419181738微信图片_20220419181718.jpg', 'https://www.newhorizonbio.com//uploadImage/202205070958352222.jpg', 'https://www.newhorizonbio.com//uploadImage/20220509110309天府-官网.jpg'],
      areasClass: false,
      wishClass: false,
      bannerHeight: '',
      topPicture: '',
      about: {},
      page_num: 1,
      page_size: 10000,
      total: 0,
      videoForm: {},
      videoDescription: '诺辉健康',
      wish_list: [],
      areas_list: [],
      areas_img: '',
      activity: {},
      linguisticType: localStorage.getItem('linguisticType'),
      isShow: true
    };
  },

  computed: { ...mapState({
      device: state => state.device
    })
  },
  watch: {// device(){
    //     let that =this
    //     setTimeout(function () {
    //         this.getAbout()
    //     },0)
    // }
  },

  created() {
    // this.getList()
    this.getAbout();
    this.getImg();
  },

  // mounted(){
  //     window.addEventListener('scroll',this.handleScroll)
  //     this.bannerHeight = document.documentElement.clientHeight - 100
  // },
  // beforeDestroy() {
  //     window.removeEventListener('scroll',this.handleScroll)
  // },
  methods: {
    getImg() {
      this.$http.get(this.$api.listTopPicture, {
        params: {
          pictureType: 0
        }
      }).then(res => {
        if (res.code == 0) {
          if (res.data.rows.length > 0) {
            this.topPicture = res.data.rows[0];
          }
        }
      });
    },

    getList() {
      this.$http.get(this.$api.getVideoList, {
        params: {
          pageNum: 1,
          pageSize: 10000
        }
      }).then(res => {
        if (res.code == 0) {
          this.videoForm = res.data.rows[0];
        }
      });
    },

    // handleScroll(){
    //     let _this = this
    //     // 将需要触发的元素添加ref，并用一个布尔值来控制类名
    //     let refArray = [
    //         {ref: 'areas', boolean: 'areasClass',customized:0},
    //         {ref: 'wish', boolean: 'wishClass',customized:20},
    //     ]
    //     refArray.forEach(item => {
    //         _this.rollMechanism(item.ref,item.customized, () => {
    //             _this[item.boolean] = true
    //         })
    //     })
    // },
    // rollMechanism(dom,customized,callback){  // dom 操作的元素; customized 自定义额外增加的高度； callback 回调函数
    //     // 滚动条距离
    //     var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    //     // 浏览器可视区域高度
    //     var clientHeight = document.documentElement.clientHeight
    //     // 元素距离顶部的距离
    //     var offsetTop = dom ? this.$refs[dom].offsetTop : null
    //     // 元素自身高度
    //     var offsetHeight = this.$refs[dom].offsetHeight
    //     // 触发dom时的高度 = 元素距离顶部的距离-元素自身高度-浏览器可视区域高度
    //     var showHeight = offsetTop - offsetHeight - clientHeight + customized
    //     if(scrollTop >= showHeight){
    //         callback && callback()
    //     }
    // },
    getAbout() {
      this.$http.get(this.$api.getAboutUsList, {
        params: {
          linguisticType: this.linguisticType
        }
      }).then(res => {
        console.log(res);

        if (res.code == 0) {
          res.data.rows.forEach(item => {
            if (item.dataType === 1) {
              this.about = item;
            } else if (item.dataType === 2) {
              this.areas_list.push(item);
              this.areas_img = item.pictureAddress;
            } else if (item.dataType === 3) {
              this.wish_list.push(item);
            } else if (item.dataType === 4) {
              this.activity = item;
            }
          });
        } else {
          this.isShow = false;
        }
      }).catch(err => {
        this.isShow = false;
      });
    }

  }
};