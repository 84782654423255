var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "content" } }, [
    _c("div", { staticClass: "top-img-box" }, [
      _c("img", {
        staticClass: "top-img",
        attrs: { src: _vm.apiTitle + _vm.topPicture.pictureAddress }
      })
    ]),
    _c("div", { staticClass: "aboutSubtitle" }, [
      _vm.linguisticType == 0
        ? _c("span", { staticClass: "span-subtitle" }, [
            _vm._v(_vm._s(_vm.about.module))
          ])
        : _vm._e(),
      _vm.linguisticType == 1
        ? _c("span", { staticClass: "span-subtitle" }, [
            _vm._v(_vm._s(_vm.about.module))
          ])
        : _vm._e()
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShow,
            expression: "isShow"
          }
        ],
        staticClass: "aboutitem"
      },
      [
        _c("div", { staticClass: "aboutitem-word animated fadeInUp" }, [
          _c("p", { domProps: { innerHTML: _vm._s(_vm.about.note) } })
        ])
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShow,
            expression: "isShow"
          }
        ],
        ref: "wish",
        staticClass: "wish"
      },
      _vm._l(_vm.wish_list, function(v, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "wishitem",
            class: _vm.wishClass ? "animated rotateInDownLeft" : ""
          },
          [
            _c("div", { staticClass: "wishImgBox" }, [
              _c("img", {
                staticStyle: { width: "65px", height: "65px" },
                attrs: { src: _vm.apiTitle + v.pictureAddress }
              })
            ]),
            _c("div", [
              _c("h3", [_vm._v(_vm._s(v.module))]),
              _c("p", { domProps: { innerHTML: _vm._s(v.note) } })
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }